import React from "react"
import { graphql, StaticQuery, Link } from 'gatsby';
import facebook from "../images/facebook.png";
import location from "../images/location.png";
import hotelCombined from "../images/hotel-combined.png";
import lilizela from "../images/lilizela.png";
import tripAdvisorBadge from "../images/awards-bottom-recommended.gif";
import bookingBadge from "../images/awards-bottom-reviews.gif";

const Footer = () => (
  <StaticQuery query={graphql`
  {
      allWordpressWpApiMenusMenusItems(filter: {
        name:{
          eq:"footer-menu"
        }
      }){
        edges{
          node{
            name
            items{
              title
              object_slug
            }
          }
        }
      }
      booknow: wordpressPage(slug: { eq: "book-now" }){
        acf {
            in_footer
        }
        slug
      }
      home: wordpressPage(slug: { eq: "home" }){
        acf {
            notice
        }
        
      }
    }
  `} render={props => (
      <footer >
        <div className="footer-wrapper">
          <div className="frame">
            <a className="footer-book-now" href="https://www.nightsbridge.co.za/bridge/Search?bbid=11280" target="_blank">
              <span>{ props.booknow.acf.in_footer }</span>
            </a>
            {/* <div className="covid19-notice" dangerouslySetInnerHTML={{ __html: props.home.acf.notice }}></div> */}

              <div className="footer-section">
              <div className="footer-menu">
                <div className="title">138 Marine</div>
                <div className="menu">
                  {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                    <div key={item.title} className="menu-item ">
                      - <Link to={`/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="footer-connect">
                <div className="title">Connect with us</div>  
                <div className="social">
                  <div className="social-item">
                    <img src={ facebook } alt="facebook" />
                    <a className="content" href="#">Facebook</a>
                  </div>
                  <div className="social-item">
                    <img src={ location } alt="location" />
                    <div className="content">138 Kus Road <br /> Sand Bay / Sandbaai <br /> Hermanus 7200 <br />SOUTH AFRICA <br />+27 (0)28 316 3447</div>  
                  </div>
                </div>
              </div>
              <div className="footer-images">
                <a href="https://www.tripadvisor.com/Hotel_Review-g12406123-d1018883-Reviews-138_Marine_Beachfront_Guesthouse-Sandbaai_Hermanus_Overstrand_Overberg_District_West.html" target="_blank"><img className="trip-advisor" src={ tripAdvisorBadge } alt="trip-advisor" /></a>
                <a href="https://www.tripadvisor.com/Hotel_Review-g12406123-d1018883-Reviews-138_Marine_Beachfront_Guesthouse-Sandbaai_Hermanus_Overstrand_Overberg_District_West.html" target="_blank"><img className="trip-advisor" src={ bookingBadge } alt="trip-advisor" /></a>
                {/* <a href="https://www.booking.com/hotel/za/138-marine-beachfront-guesthouse.html" target="_blank"><img className="hotel-combined" src={ bookingBadge } alt="hotel-combined" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
  )} />
)

export default Footer
