import React, { Component } from "react"
import { graphql, StaticQuery, Link } from 'gatsby';
import logo from "../images/logo.png";

const isSSR = typeof window === "undefined";

class Header extends Component {
  
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  state = {
    isMenuOpened: false,
    isFixedMenuOpened: false,
    isSubMenuOpened: false,
    subMenuSlug: null,
    isFixedSubMenuOpened: false,
    subFixedMenuSlug: null,
    isFixedHeader: false,
    isPopUpOpen: false
  };

  openPopup = (e) => {
    e.preventDefault();
    this.setState({
        isPopUpOpen: true
    }); 
  };

  openSubMenu = (e, slug) => {
    if(window.innerWidth < 992) {
      e.preventDefault();
      this.setState( ( prevState ) => {
        return { isSubMenuOpened: !prevState.isSubMenuOpened, subMenuSlug: slug };
      });
    }
  }

  openFixedSubMenu = (e, slug) => {
    if(window.innerWidth < 992) {
      e.preventDefault();
      this.setState( ( prevState ) => {
        return { isFixedSubMenuOpened: !prevState.isFixedSubMenuOpened, subFixedMenuSlug: slug };
      });
    }
  }

  openMenu = () => {
    if(!this.state.isMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    this.setState( ( prevState ) => {
      return { isMenuOpened: !prevState.isMenuOpened };
    });
  }

  closeMenu = () => {
    document.body.style.overflow = 'unset';
    this.setState( () => {
      return { isMenuOpened: false, isFixedMenuOpened: false };
    });
  }

  openFixedMenu = () => {
    if(!this.state.isFixedMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    this.setState( ( prevState ) => {
      return { isFixedMenuOpened: !prevState.isFixedMenuOpened };
    });
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isPopUpOpen: false
    }); 
    }
  }

  handleScroll = () => {
    if(window.pageYOffset > 100) {
      this.setState({
        isFixedHeader: true
      });
    } else {
      this.setState({
        isFixedHeader: false
      });
    }
    
  }

  render() {
  return <StaticQuery query={graphql`
  {
      allWordpressWpApiMenusMenusItems(filter: {
        name:{
          eq:"main-menu"
        }
      }){
        edges{
          node{
            name
            items{
              title
              object_slug
              url
              wordpress_children {
                title
                object_slug
              }
            }
          }
        }
      }
      booknow: wordpressPage(slug: { eq: "book-now" }){
        acf {
            in_header
        }
        slug
      }
      home: wordpressPage(title: { eq: "Home" }){
        acf {
            popup_banner {
                source_url
            }
            popup_image {
                source_url
            }
            popup_pre_heading
            popup_discount
            popup_discount_text
            popup_claim_text
            popup_post_heading
            popup_booknow_button
            popup_buooknow_button_link
        }
    }
    }
  `} render={props => (
    !isSSR && (
      <header >
        <div className="header-wrapper">
          <div className="header-logo">
            <Link to="/">
            <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className={"header-menu "  + ( this.state.isMenuOpened ? 'menu-opened' : '')} >
            {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                <div key={item.title} className={"menu-item " + (item.wordpress_children ? 'has-children' : '')}>
                  { item.url === '#' ? (
                      <a onClick = {(event) => this.openSubMenu(event, item.object_slug)} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                    ) : (
                      <Link className={( window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={`/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                    ) }
                  {(item && item.wordpress_children) ? (
                    <div className={ "sub-menu arrow_box " + ( this.state.isSubMenuOpened && this.state.subMenuSlug === item.object_slug ? 'sub-menu-opened' : '')}>
                      {item && item.wordpress_children && item.wordpress_children.map(child => {
                        return (
                            <Link to={`/${child.object_slug}`} key={child.title} dangerouslySetInnerHTML={{ __html: child.title}}></Link>
                        )
                        })}
                    </div>
                  ): null}
                </div>
            ))}
            <a className="book-now" href="https://www.nightsbridge.co.za/bridge/Search?bbid=11280" target="_blank">
              <span className="title">{ props.booknow.acf.in_header }</span><br />
            </a>
            
          </div>

          <div className={ "mobilebutton " + ( this.state.isMenuOpened ? 'menu-opened' : '')}>
            <a className="menu-btn menu-btn1" onClick={this.openMenu}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </a>
          </div>              

        </div>

        <div className={"header-wrapper headermenu-f " + (this.state.isFixedHeader ? "headermenu-v" : "") }>
          <div className="header-logo">
            <Link to="/">
            <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className={"header-menu " + ( this.state.isFixedMenuOpened ? 'menu-opened' : '')}>
            {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                <div key={item.title} className={"menu-item " + (item.wordpress_children ? 'has-children' : '')}>
                    { item.url === '#' ? (
                      <a onClick = {(event) => this.openFixedSubMenu(event, item.object_slug)} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                    ) : (
                      <Link className={( window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={`/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                    ) }
                  {(item && item.wordpress_children) ? (
                    <div className={ "sub-menu arrow_box " + ( this.state.isFixedSubMenuOpened && this.state.subFixedMenuSlug === item.object_slug ? 'sub-menu-opened' : '')}>
                      {item && item.wordpress_children && item.wordpress_children.map(child => {
                        return (
                            <Link to={`/${child.object_slug}`} key={child.title} dangerouslySetInnerHTML={{ __html: child.title}}></Link>
                        )
                        })}
                    </div>
                  ): null}
                </div>
            ))}
            {/* <Link className="book-now" to={`/${props.booknow.slug}`}>
              <span className="title">{ props.booknow.acf.in_header }</span><br />
            </Link> */}
          </div>

          <div className={ "mobilebutton " + ( this.state.isFixedMenuOpened ? 'menu-opened' : '')}>
            <a className="menu-btn  menu-btn2" onClick={this.openFixedMenu}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </a>
          </div>

        </div>

        <div className="banner-discount" onClick = {(event) => this.openPopup(event)} >
            <div className="banner-inner">
                <img src={ props.home.acf.popup_image.source_url } />
                <div className="banner-text">
                    <div className="left">
                        {/* { props.home.acf.popup_discount } */}
                    </div>
                    <div className="right">
                        {/* <div className="claim">{ props.home.acf.popup_claim_text }</div>
                        <div className="discount">{ props.home.acf.popup_discount_text }</div> */}
                        <div className="claim">Click here to claim your</div>
                        <div className="claim-large">DISCOUNT</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="header-popup fade-in" style={{display: this.state.isPopUpOpen ? 'flex' : 'none' }}>
          <div className="header-popup-inner" ref={this.setWrapperRef}>
              <img src={ props.home.acf.popup_banner.source_url } />
              <div className="pre-heading">{ props.home.acf.popup_pre_heading }</div>
              <div className="heading">
                  { props.home.acf.popup_discount }
                  <span>{ props.home.acf.popup_discount_text }</span>
              </div>
              <div className="post-heading">{ props.home.acf.popup_post_heading }</div>
          </div>
        </div>

      </header>
      )
  )} />
  }
};

export default Header
