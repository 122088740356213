import React from 'react'
import Helmet from "react-helmet"
import { withPrefix } from "gatsby" 
import Header from "../components/header"
import Footer from "../components/footer"

const DefaultTemplate = ({ children }) => (
    <div>
      <Helmet>
        {/* <title>138 Marine, Beachfront Guesthouses, Western Cape, South Africa</title>
        
        <meta name='description' content='138 Marine is an luxurious, upmarket guest house with spectacular, unspoiled sea front views and exquisite mountain scenery.  
        There are four double rooms, each individually decorated and luxuriously fitted, meeting international standards. 138 Marine provides an enchanting base from which to 
        explore one of the most magnificent eco-destinations, renowned for its abundance of whales, great white sharks, and unique variety of flora species.' />
       
        <meta name='keywords' content='Guesthouses, Cape Guesthouses, Bed & Breakfasts, Bed & Breakfast Directory, Cape Bed & Breakfast, Bed & Breakfast, Bed and Breakfasts, 
        B&b, B&bs, B And B, B And Bs, Bed Breakfasts, Bed Breakfast, Bed And Breakfasts, Bed & Breakfast, Bed&breakfasts, Bed&breakfast, Bedbreakfasts, Bedbreakfast, Lodging, 
        Western Cape Lodging, Winelands Lodging,  Accommodation, Accommodations,  Accommodation In SA, Southafrican Accommodation, Car Rental, Car Hire, Cape Coast Accommodation,
        Southern African Accommodation, Sea View Accommodation, Holiday Accommodation, Bed & Breakfast, Bed Breakfasts, Breakfast, Bed Breakfast Inns, Bed Breakfast, 
        Bed & Breakfast, Vacations, Vacation Packages, Vacation Rentals, Cape Vacation, Family Vacations, Whale Watching, Tourism, Cape Tourism,  Tourism Cape, South African 
        Tourism, Sa Tourism, South Africa Tourism, South African Tourism, beach front guest house, beachfront guest house, guest house south africa, Hermanus, Western Cape, 
        South Africa' /> */}
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js" type="text/javascript" /> */}
        {/* <script src={withPrefix('jquery.min.js')} type="text/javascript" /> */}
        <script src={withPrefix('jquery-ui.min.js')} type="text/javascript" />
        <script src={withPrefix('studio137.js')} type="text/javascript" />
      </Helmet>
      <Header />
      { children }
      <Footer />
    </div>
  )
  
export default DefaultTemplate
  
   
